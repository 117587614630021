<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results?.data || []"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  :total="items.results"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableDebtor',
  data () {
    return {
      headers: [],
      actions: [
        {
          id: 1,
          to: (item) => this.checkTypeDocument(item),
          color: 'blue',
          target: '_blank',
          name: 'mdi-information-outline',
          tooltip: 'tooltip.info',
          buttonOptions: { xSmall: false, icon: true }
        }
      ],
      link: null
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listDistributionManager,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListDistributionManager']),
    async getData (params) {
      this.headers = [
        { value: 'count', text: this.$t('itemsAmount'), align: 'center', total: { global: 'global_sum_count', page: 'page_count_sum', align: 'center' }, sortable: false },
        { value: 'distribution_sum', text: this.$t('distribution'), align: 'center', total: { global: 'global_form2_distribution', page: 'global_form2_distribution', align: 'center' }, sortable: false },
        { value: 'profit_sum', text: this.$t('profit'), align: 'center', total: { global: 'global_form2_profit', page: 'page_form2_profit', align: 'center' }, sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ]
      await this.getListDistributionManager({ params: { ...params, ...this.$route.params }, link: this.checkLink() })
    },
    checkTypeDocument (item) {
      switch (this.$route.params.typeDocument) {
        case 'sqc':
        case 'crewing_manager':
          if (!this.headers.find(column => column.value === 'crewingManagerFullName')) {
            this.headers.unshift({
              value: 'crewingManagerFullName',
              text: this.$t('crewingManager'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({ name: 'distribution-usual-report-sailor-manager', params: { manager: item.manager.id }, query: { ...this.$route.query } })
        case 'dpd':
          if (!this.headers.find(column => column.value === 'crewingManagerFullName')) {
            this.headers.unshift({
              value: 'crewingManagerFullName',
              text: this.$t('crewingManagerFullName'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({ name: 'distribution-usual-report-sailor-type-document', params: { manager: item.manager.id }, query: { ...this.$route.query } })
        case 'portal':
        case 'sc':
          if (!this.headers.find(column => column.value === 'crewingManagerFullName')) {
            this.headers.unshift({
              value: 'crewingManagerFullName',
              text: this.$t('crewingManagerFullName'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({ name: 'distribution-usual-report-sailor-portal', params: { manager: item.manager.id }, query: { ...this.$route.query } })
        case 'eti':
          if (!this.headers.find(column => column.value === 'course')) {
            this.headers.unshift({
              value: 'course',
              text: this.$t('course'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({ name: 'distribution-usual-report-sailor-institution-course', params: { ...this.$route.params, course: item.course.id }, query: { ...this.$route.query } })
        case 'medical':
          if (!this.headers.find(column => column.value === 'doctor')) {
            this.headers.unshift({
              value: 'doctor',
              text: this.$t('doctor'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({ name: 'distribution-usual-report-sailor-medical', params: { medical: item.doctor?.id || 0 }, query: { ...this.$route.query } })
        default:
          return ({})
      }
    },
    checkLink () {
      switch (this.$route.params.typeDocument) {
        case 'sqc':
        case 'crewing_manager':
        case 'portal':
        case 'sc':
          return 'crewingManager'
        case 'dpd':
          return 'manager'
        case 'eti':
          return 'course'
        case 'medical':
          return 'doctor'
        case 'adv_training':
          return
        default:
          return 'crewingManager'
      }
    }

  }
}
</script>
